import type { NextPage } from 'next';
import { Grid, ThemeProvider } from '@mui/material';
import theme from '../styles/theme';
import Image from 'next/image';

import logoGascaribe from '../public/images/Logo_Gases_Two_lines.svg';
import CompanyButton from '../components/CompanyButton';
import { Policy } from 'ui';
import useUser from '../auth/useUser';

const Home: NextPage = () => {
  const currentUser = useUser();

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        display="flex"
        direction="column"
        alignItems="center"
        marginTop={5}
      >
        <Policy user={currentUser} roles={['mensajeria_GDC']}>
          <Grid item>
            <CompanyButton link="/GDC">
              <Image src={logoGascaribe} alt="Gascaribe" width={230} />
            </CompanyButton>
          </Grid>
        </Policy>
      </Grid>
    </ThemeProvider>
  );
};

export default Home;
